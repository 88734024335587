<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        :href="companyUrl"
        target="_blank"
      >{{ companyName }}</b-link>
      <span class="d-none d-sm-inline-block">, 版权所有</span>
    </span>

    <span class="float-md-right d-none d-md-block">匠心 &amp; 智造
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
  },
  setup() {
    // Company Name &   Company Url
    const { companyName, companyUrl } = $themeConfig.app
    return {
      companyName,
      companyUrl,
    }
  },
}
</script>

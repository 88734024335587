export default [
  {
    header: '推广服务',
    resource: 'extension',
    action: 'index',
  },
  {
    title: '员工管理',
    icon: 'UserIcon',
    route: 'staff-list',
    resource: 'extension-staff',
    action: 'index',
  },
  {
    title: '名片管理',
    icon: 'CreditCardIcon',
    route: 'callcard-list',
    resource: 'extension-card',
    action: 'index',
  },
  {
    title: '项目管理',
    icon: 'DatabaseIcon',
    resource: 'extension-project',
    action: 'index',
    children: [
      {
        title: '项目列表',
        route: 'project-list',
        resource: 'extension-project-list',
        action: 'index',
      },
      {
        title: '项目产品',
        route: 'product-list',
        resource: 'extension-project-product',
        action: 'index',
      },
      {
        title: '项目政策',
        route: 'policy-list',
        resource: 'extension-project-policy',
        action: 'index',
      },
      {
        title: '图册管理',
        route: 'image-list',
        resource: 'extension-project-picture',
        action: 'index',
      },
      {
        title: '视频管理',
        route: 'video-list',
        resource: 'extension-project-video',
        action: 'index',
      },
    ]
  },
  {
    title: '员工资源',
    icon: 'CloudDrizzleIcon',
    resource: 'extension-resources',
    action: 'index',
    children: [
      {
        title: '海报分类',
        route: 'staff-image-cate',
        resource: 'extension-resources-posterCate',
        action: 'index',
      },
      {
        title: '海报管理',
        route: 'staff-image-list',
        resource: 'extension-resources-poster',
        action: 'index',
      },
      {
        title: '视频分类',
        route: 'staff-video-cate',
        resource: 'extension-resources-videoCate',
        action: 'index',
      },
      {
        title: '视频管理',
        route: 'staff-video-list',
        resource: 'extension-resources-video',
        action: 'index',
      },
    ]
  },
  {
    title: '话术管理',
    icon: 'HeadphonesIcon',
    resource: 'extension-library',
    action: 'index',
    children: [
      {
        title: '话术分类',
        route: 'talks-cate',
        resource: 'extension-library-cate',
        action: 'index',
      },
      {
        title: '话术列表',
        route: 'talks-list',
        resource: 'extension-library-list',
        action: 'index',
      },
    ]
  },
  {
    title: '管理报表',
    icon: 'PieChartIcon',
    resource: 'extension-report',
    action: 'index',
    children: [
      {
        title: '要素推广汇总',
        route: 'resources-share-statistics',
        resource: 'extension-report-element',
        action: 'index',
      },
      {
        title: '员工推广统计',
        route: 'staff-share-statistics',
        resource: 'extension-report-staff',
        action: 'index',
      },
      {
        title: '客户线索统计',
        route: 'customer-clue-statistics',
        resource: 'extension-report-customer',
        action: 'index',
      },
    ]
  },
]

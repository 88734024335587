import request from '@/auth/jwt/request'

/**
 * @description -- 账号登录 --
 * @param {Object} param params {Object} 传值参数
 */
export const login = data => { return request('adminuser/login', 'post', {}, data, { noAuth: false }) }

/**
 * @description -- 账号登录 --
 * @param {Object} param params {Object} 传值参数
 */
export const qylogin = data => {
  return request('adminuser/qyLogin', 'post', {}, data, { noAuth: false })
}

/**
 * @description -- 修改密码 --
 * @param {Object} param params {Object} 传值参数
 */
export const resetPassword = data => { return request('reset/password', 'post', {}, data) }

/**
 * @description -- 账号登录 --
 * @param {Object} param params {Object} 传值参数
 */
export const loginOut = () => { return request('adminuser/logout', 'get')}


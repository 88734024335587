export default [
  {
    header: '系统管理',
  },
  {
    title: '组织架构',
    icon: 'AlignLeftIcon',
    route: 'system-depart',
  },
  {
    icon: 'FileIcon',
    title: '附件管理',
    route: 'system-files',
  },
  {
    title: '权限管理',
    icon: 'BookOpenIcon',
    children: [
      {
        title: '权限规则',
        route: 'rules-competency',
      },
      {
        title: '角色管理',
        route: 'role-list',
      },
      {
        title: '账号管理',
        route: 'account-list',
      },
      {
        title: 'API接口',
        route: 'api-list',
      },
    ],
  },
  // {
  //   title: '系统配置',
  //   icon: 'ApertureIcon',
  //   route: 'config-design',
  // },
  // {
  //   title: '组合数据',
  //   icon: 'CrosshairIcon',
  //   route: 'combined-data',
  // },
]

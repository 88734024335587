export default [
  {
    header: '网站管理',
    resource: 'website',
    action: 'index',
  },
  {
    title: '官网信息',
    icon: 'AirplayIcon',
    route: 'website-web',
    resource: 'website-web',
    action: 'index',
  },
  {
    title: '新闻动态',
    icon: 'FeatherIcon',
    resource: 'website-news',
    action: 'index',
    children: [
      {
        title: '新闻分类',
        route: 'news-cate',
        resource: 'website-news-cate',
        action: 'index',
      },
      {
        title: '新闻管理',
        route: 'news-list',
        resource: 'website-news-list',
        action: 'index',
      },
    ]
  },
  {
    title: '官网业务',
    icon: 'BookOpenIcon',
    resource: 'website-business',
    action: 'index',
    children: [
      {
        title: '业务分类',
        route: 'zixun-cate',
        resource: 'website-business-cate',
        action: 'index',
      },
      {
        title: '业务管理',
        route: 'zixun-list',
        resource: 'website-business-list',
        action: 'index',
      },
    ]
  },
  {
    title: '客户留言',
    icon: 'CoffeeIcon',
    route: 'feedback-list',
    resource: 'website-feedback',
    action: 'index',
  },
]
